import type { AppProps } from 'next/app'
import Script from 'next/script'
import 'polyfill-object.fromentries'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '../app/styles/globals.sass'
import { MetaPixelScript } from '../packages/@necktip/next/gtm'

export const CookieContext = React.createContext<{
	cookieLevel: string
	setCookieLevel: (level: string) => void
	// eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ cookieLevel: 'initial', setCookieLevel: () => {} })

const queryClient = new QueryClient()
function MyApp({ Component, pageProps }: AppProps) {
	;(global as any).__DEV_MODE__ = process.env.NODE_ENV === 'development'

	const [cookieLevel, setCookieLevel] = React.useState<string>('waiting')

	return (
		<CookieContext.Provider value={{ cookieLevel, setCookieLevel }}>
			<QueryClientProvider client={queryClient}>
				<Script
					src="https://www.googletagmanager.com/gtag/js?id=G-4BTGV6TC9E"
					strategy="afterInteractive"
				/>
				<Script id="google-analytics" strategy="afterInteractive">
					{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());

						gtag('config', 'G-4BTGV6TC9E');
						gtag('consent', 'default', {
							ad_storage: 'denied',
							analytics_storage: 'denied',
						});
				`}
				</Script>
				<MetaPixelScript />
				<Component {...pageProps} />
			</QueryClientProvider>
		</CookieContext.Provider>
	)
}
export default MyApp
