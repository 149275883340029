import Script from 'next/script'
import { getConfig } from '../../../../app/next/config'

function getGtmTrackingId(): string | null {
	const config = getConfig()?.publicRuntimeConfig ?? {}

	if ('GTM_TRACKING_ID' in config) {
		return config?.GTM_TRACKING_ID ?? null
	}

	return null
}

export function GtmScriptTag(props: { id?: string }) {
	const GTM_TRACKING_ID = props.id ?? getGtmTrackingId()

	return GTM_TRACKING_ID ? (
		<Script strategy="afterInteractive">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`}</Script>
	) : null
}

export function GtmNoscriptTag(props: { id?: string }) {
	const GTM_TRACKING_ID = props.id ?? getGtmTrackingId()

	return (
		<noscript
			key="gtm-noscript"
			dangerouslySetInnerHTML={{
				__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
			}}></noscript>
	)
}

export function MetaPixelScript() {
	return (
		<Script
			id="metaPixel"
			strategy="afterInteractive"
			dangerouslySetInnerHTML={{
				__html: `
				!function(f,b,e,v,n,t,s)
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '2073422616163118');
				fbq('track', 'PageView');
				`,
			}}
		/>
	)
}

export function MetaPixelNoscript() {
	return (
		<noscript
			dangerouslySetInnerHTML={{
				__html: `<img
	height="1"
	width="1"
	style="display:none"
	src="https://www.facebook.com/tr?id=2073422616163118&ev=PageView&noscript=1"
/>`,
			}}
		/>
	)
}
